<template>
<el-dialog v-model="visible" :title="title + ' - 详情'" :before-close="close" custom-class="cost-release-dialog">
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txt_project_name" label="所属项目" align="center" show-overflow-tooltip />
    <el-table-column prop="txt_filter_number" label="受试者筛选号" align="center" show-overflow-tooltip />
    <el-table-column prop="txt_examinee_bankcard" label="银行卡号" align="center">
      <template #default="scope">
        {{ scope.row.txt_examinee_bankcard||"- -" }}
      </template>
    </el-table-column>
    <el-table-column prop="dec_distribution_amount" label="费用金额（元）" align="center">
      <template #default="scope">
        {{ scope.row.dec_distribution_amount }}
      </template>
    </el-table-column>
    <el-table-column prop="txt_status" label="发放状态" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_status==1">
          <p class="text-red">异常</p>
          <p>（{{scope.row.txt_error_message}}）</p>
        </template>
        <template v-else-if="scope.row.txt_status==2">成功</template>
        <template v-else>- -</template>
      </template>
    </el-table-column>
  </el-table>
  <div class="crd-load-comp" v-if="table.total!=table.data.length" @click="getDetail(1)">{{ table.loadTest }}</div>
  <template #footer>
    <div class="dialog-footer">
      <el-checkbox v-model="checkboxChecked" @change="getDetail(0)" label="仅加载发放失败数据" size="large" />
      <div class="crd-footer-text"><span class="text-red">{{ release.int_request_success_number||0 }}</span> / {{ release.int_request_total_number||0 }} 个申请，共发放 <span class="text-red"> {{ release.dec_request_success_amount||0.00 }}</span> / {{release.dec_request_total_amount||0.00}} 元</div>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 详情 - 发放记录/费用管理
import { computed, onMounted, reactive, toRefs } from "vue";
import { getRecordDetail, getSuccessNumForRecord } from "api/apis.js";
export default {
  props: {
    propData: { type: Object },
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      checkboxChecked: false,
      title: computed(() => prop.propData.txt_distribution_request_no),
      table: {
        loadTest: "加载更多",
        page: 1,
        data: [],
        loading: false,
        total: 0
      },
      release: {
        dec_request_total_amount: null,
        int_request_total_number: null,
        dec_request_success_amount: null,
        int_request_success_number: null
      }
    })

    onMounted(() => {
      getDetail(0);
      getNums();
    })

    // 获取发放笔数
    const getNums = () => {
      getSuccessNumForRecord({
        t_distribution_request_id: prop.propData.t_distribution_request_id
      }).then(response => {
        if (response.code == 200) {
          state.release = response.data;
        } else {
          state.release = {
            dec_request_total_amount: null,
            int_request_total_number: null,
            dec_request_success_amount: null,
            int_request_success_number: null
          };
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取详情
    const getDetail = plus => {
      state.table.loading = true;
      state.table.page = (plus == 0 ? 1 : state.table.page + plus);
      if (plus == 0) state.table.data = [];
      //发放状态 1-失败 2-成功
      let status = state.checkboxChecked ? 1 : null;
      getRecordDetail({
        t_distribution_request_id: prop.propData.t_distribution_request_id,
        txt_transaction_status: status,
        pages: state.table.page,
        pageSize: 10
      }).then(response => {
        if (response.code == 200) {
          state.table.data = state.table.data.concat(response.data);
          state.table.total = response.total;
          if ((response.data || []).length == 0) {
            state.table.loadTest = "没有更多了";
          } else {
            state.table.loadTest = "加载更多";
          }
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    }

    const close = () => {
      ctx.emit("close");
    };

    return {
      ...toRefs(state),
      close,
      getDetail,
      getNums
    }
  }
}
</script>

<style lang="scss">
.cost-release-dialog {
  margin: 0;
  height: calc(100% - 16vh);

  .el-dialog__body {
    padding-top: 14px;
    padding-bottom: 10px;
    box-sizing: border-box;
    height: calc(100% - 130px);
  }

  p {
    margin: 0;
  }

  .text-red {
    font-weight: bold;
    color: #ff0000;
  }

  .crd-load-comp {
    font-weight: bold;
    color: #e6a23c;
    cursor: pointer;
    text-align: center;
    margin: 8px 0;
    user-select: none;
    text-decoration: underline;
  }

  .el-dialog__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .dialog-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .crd-footer-text {
        font-size: 14px;
        margin-left: 24px;
      }

      .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #606266;
      }

      .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #d1c9f5;
        border-color: #d1c9f5;
      }
    }
  }
}
</style>
